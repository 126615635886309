import './Home.scss';
import 'react-splitter-layout/lib/index.css';
import axios from 'axios';
import PanoramaContainer from '../containers/panorama-container/PanoramaContainer';
import { useContext, useEffect, useRef, useState } from 'react';
import { Navbar } from '../layouts/navbar/Navbar';
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from '../../state/hooks';
import { useDispatch } from 'react-redux';
import { setLogOff, setLoginStatus, setUserDetails } from '../../state/features/loginSlice';
import { MapContainer } from '../containers/map-container/MapContainer';
import { setIsMultiOpen } from '../../state/features/panoramaSlice';
import { Basemap } from '../basemap/Basemap';
import { Legend } from '../legend/Legend';
import { Toast } from 'primereact/toast';
import { Information } from '../shared/information/Information';
import { setAllFields, setDomainFields } from '../../state/features/domainSlice';
import { setIsLayerVisible, setProjects, setServices } from '../../state/features/layerSlice';
import { Sidebar } from '../layouts/sidebar/Sidebar';
import { Table } from '../shared/table/Table';
import { ServiceRestApi } from '../../util/restapi/services';
import { DigiCallbackContextProvider } from '../containers/shared/digitiziation/DigiCallbackContextProvider';
import { ProjectRestApi } from '../../util/restapi/project';
import { addLocale, locale } from 'primereact/api';
import { Help } from '../help/Help';
import { Import } from '../import/Import';
import { setButtonState, setShowInfoFeature, setShowInfoWindow } from '../../state/features/buttonStateSlice';
import { ButtonState } from '../shared/button-state/ButtonState';
import { Print } from '../print/Print';
import { LoginRestApi } from '../../util/restapi/login';
import { Export } from '../export/Export';
import { Footer } from '../layouts/footer/Footer';
import { ScreenShot } from '../screenshot/ScreenShot';
import Splitter from "m-react-splitters";
import { setToken } from '../../state/features/customizeSlice';
import { useTranslation } from 'react-i18next';
import { setUrlView } from '../../state/features/urlSlice';
import { Intro } from '../intro/Intro';
import { setDigiPopupVisibility } from '../../state/features/digitizationSlice';
interface QueryParams {
    [key: string]: string | undefined;
}
export const Home = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const toast = useRef<any>();
    const [showTable, setShowTable] = useState<boolean>(false)
    const [selectedPrint, setSelectedPrint] = useState<any>(null)
    const loginStatus = useAppSelector(state => state.login.loginStatus);
    const toastVisibility = useAppSelector(state => state.login.showToast);
    const isFullScreen = useAppSelector(state => state.panorama.isFullScreen);
    const projects: any = useAppSelector(state => state.layer.projects);
    const legendVisibility: any = useAppSelector(state => state.map.legendVisibility);
    const tableVisibility: any = useAppSelector(state => state.buttonState.tableButton);
    const sidebarVisibility: any = useAppSelector(state => state.buttonState.sidebarButton);
    const screenshotVisibility = useAppSelector(state => state.buttonState.screenshotButton)
    const showInfoWindow = useAppSelector(state => state.buttonState.showInfoWindow)
    const selectedFeature: any = useAppSelector(state => state.selectedFeature.feature);
    const selectedLayer: any = useAppSelector(state => state.layer.selectedLayer)
    const language = useAppSelector(state => state.language.value);
    const buttonState = useAppSelector(state => state.buttonState.buttonState);
    const importCompleted = useAppSelector((state) => state.import.importCompleted)
    const displayPrint = useAppSelector((state) => state.buttonState.printButton);
    const displayExport = useAppSelector((state) => state.buttonState.exportButton);
    const panoramaType = useAppSelector(state => state.panorama.type);
    const isPanoMiniScreen = useAppSelector(state => state.panorama.isPanoMiniScreen);
    const mapCoordinate = useAppSelector((state: any) => state.url.mapCoord)
    const angle = useAppSelector((state) => state.panorama.angle)
    const panoramaCoordinate: any = useAppSelector(state => state.panorama.panoramaCoordinate)
    const urlView: any = useAppSelector(state => state.url.urlView)
    const token = useAppSelector((state: any) => state.customize.token);
    const location = useLocation()
    const logoff:any = useAppSelector((state: any) => state.login.logoff)
    const { REACT_APP_BASE_URL } = process.env

    const sidebarButton: boolean = useAppSelector((state: any) => state.buttonState.sidebarButton)
    const sidebarWidth: number = useAppSelector((state: any) => state.styleControl.sidebarWidth)
    useEffect(() => {
        if(logoff){
            window.location.reload();
        } 
        if (token) {
            getLoginUser()
        }
        dispatch(setLogOff(false)); 
    }, [token])
// Function to parse query parameters
function parseQueryParams(url: string): QueryParams {
    const queryParams: QueryParams = {};
    const queryString = url.split('@');

    queryString.forEach((param: string) => {
        const matches = param.match(/(Pan|Map|View)([^@]+)/);
        if (matches && matches.length === 3) {
            const key = matches[1];
            const value = matches[2];
            queryParams[key] = value;
        }
    });

    return queryParams;
}

    useEffect(() => {
        const handleUrlChange = () => {
            // URL değiştiğinde yapılacak işlemler

            const currentUrl = window.location.href;

            const startIndex = currentUrl.indexOf('@Pan');

            // Eğer @Pan bulunduysa, devamını alsın
            const result = startIndex !== -1 ? currentUrl.slice(startIndex) : '';

            navigate(`${result}`)
            dispatch(setUrlView(result))


            var regex1 = /@View([\d.]+),([\d.]+),([\d.]+)/;
            var match1 = result.match(regex1);

            if (match1) {
                var firstValue = match1[1];
                var secondValue = match1[2];
                var thirdValue = match1[3];

                localStorage.setItem('panoValues', firstValue + ',' + secondValue + ',' + thirdValue);
                localStorage.setItem('isCopyUrl', "copied");
            }

        };

        window.addEventListener('popstate', handleUrlChange);

        // useEffect temizleme fonksiyonu
        return () => {
            window.removeEventListener('popstate', handleUrlChange);
        };
    }, []); // useEffect, bileşen ilk render olduğunda bir kere çalışsın
    useEffect(() => {
          // Parse the query parameters
            const queryParams: QueryParams = parseQueryParams(location.pathname);

            // Extract specific parameters
            const panValue: any = queryParams['Pan'];
            const mapValue: string | undefined = queryParams['Map'];
            const viewValue: string | undefined = queryParams['View'];
            const username: any = localStorage.getItem('username');
        if (urlView !== null) {
           
            navigate(`${urlView}`);
            var regex1 = /@View([\d.]+),([\d.]+),([\d.]+)/;
            var match1 = urlView.match(regex1);
    
            if (match1) {
                var firstValue = match1[1];
                var secondValue = match1[2];
                var thirdValue = match1[3];
                localStorage.setItem('view', firstValue + ',' + secondValue + ',' + thirdValue);
            }
    
            var regex2 = /@Pan(\d+\.\d+),(\d+\.\d+)/;
            var match2 = urlView.match(regex2);
    
            if (match2) {
                var latitude = match2[1];
                var longitude = match2[2];
                // console.log("user: ",localStorage.getItem('username'));
                const username: any = localStorage.getItem('username');
                localStorage.setItem(`lastCoordinate${username}`, latitude + ',' + longitude);
            }
    
           
            
        } else if (urlView === null && panoramaCoordinate && mapCoordinate[0] && angle) {
            const storage: any = localStorage.getItem('view')?.split(',');

            if (storage && storage.length > 0) {
                navigate(`@Pan${panoramaCoordinate.lon + ',' + panoramaCoordinate.lat}@Map${mapCoordinate[0] + ',' + mapCoordinate[1]}@View${storage[0]},${storage[1]},${storage[2]}`);
                localStorage.setItem(`lastCoordinate${username}`, panoramaCoordinate.lon + ',' + panoramaCoordinate.lat );
            } else {

                    if (location.pathname.includes("/@Pan")) {
                        localStorage.setItem(`lastCoordinate${username}`, panValue);
                        // localStorage.setItem('lastCoordinate', panValue);
                        navigate(`@Pan${panValue}@Map${mapValue}@View${viewValue}`);
                    } else {
                        // navigate(`@Pan${panoramaCoordinate.lon + ',' + panoramaCoordinate.lat}@Map${mapCoordinate[0] + ',' + mapCoordinate[1]}@View${1.000},${0.0},${0.0}`);
                        try {
                            axios.get(`${REACT_APP_BASE_URL}rest/v1/pano/getAnyPanoGeom` +
                                `?token=${getToken()}`)
                                .then((response: any) => {
                                    const coordy: any = response?.data?.data?.coordy;
                                    const coordx: any = response?.data?.data?.coordx;
                                    if (response?.data?.data) {
                                        navigate(`@Pan${coordx + ',' + coordy}@Map${coordx + ',' + coordy}@View${1.000},${0.0},${0.0}`);
                                        localStorage.setItem(`lastCoordinate${username}`, coordx + ',' + coordy );
                                    }
                                    // const translated:any = transform(response.data.features[0].geometry.coordinates, 'EPSG:3857', 'EPSG:4326');
                                })
                        } catch (error) {
                        }
                    } 
                }
            }      
       /*  if (location.pathname.includes("/@Pan")) {
            localStorage.setItem('lastCoordinate', panValue);
            navigate(`@Pan${panValue}@Map${mapValue}@View${viewValue}`);
        } else {
            navigate(`@Pan${panoramaCoordinate.lon + ',' + panoramaCoordinate.lat}@Map${mapCoordinate[0] + ',' + mapCoordinate[1]}@View${1.000},${0.0},${0.0}`);

        } */
      
  
    }, [panoramaCoordinate, angle, mapCoordinate, urlView, location.pathname, navigate]);
    // Example URL

    const getToken = () => {
        return localStorage.getItem('token')
    }
// Parse the query parameters



    const getLoginUser = async () => {
        const res = await LoginRestApi.loginUser()
        if (res && res.data.success) {
            dispatch(setUserDetails(res.data.user))
        } else {
            localStorage.removeItem('token');
            localStorage.clear();
            sessionStorage.clear();
            navigate('/login');
            dispatch(setToken(undefined));
        }
    }

    useEffect(() => {
        if (panoramaType === 'Multi Display') {
            dispatch(setIsMultiOpen(true))
        } else {
            dispatch(setIsMultiOpen(false))
        }
    }, [panoramaType])

    useEffect(() => {
        axios.interceptors.response.use(
            (successfulReq) => {
                return successfulReq;
            },
            (error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        dispatch(setLoginStatus('LOGOUT'));
                        localStorage.removeItem('token');
                    }
                    else if (error.response.data?.message) {
                        dispatch(setDigiPopupVisibility(false))
                        toast.current?.show({ severity: 'error', summary: 'Error Message', detail: error.response.data.message, life: 3000 });
                    }
                    else if (!error.response.data?.message && error.response.status) {
                        // console.error("toastVisibility");
                        toast.current?.show({ severity: 'error', summary: 'Error Message', detail: `${t("MESSAGE.AxiosAutoError")} ${error.response.status}`, life: 3000 });
                    }
                    return Promise.reject(error);
                }
                else {
                    toast.current?.show({ severity: 'error', summary: 'Error Message', detail: `${t("MESSAGE.AxiosAutoError")}`, life: 3000 });
                }
            }
        );
    }, [])

    useEffect(() => {
        if (token === null) {
            dispatch(setLoginStatus('LOGOUT'))
        }
        if (token) {
            dispatch(setLoginStatus('LOGIN'))
            getProjects();
            //      localStorage.setItem('sidebarLayersAreOpened', JSON.stringify([]));
            // getServices();
        }
        document.addEventListener('contextmenu', rightClickHandler)
        return () => document.removeEventListener('contextmenu', rightClickHandler)
    }, [])

    useEffect(() => {
        if (language === 'tr') {
            addLocale('tr', {
                startsWith: ['İle Başlayan'],
                contains: ['İçerir'],
                notContains: ['İçermez'],
                endsWith: ['İle Biten'],
                equals: ['Eşittir'],
                notEquals: ['Eşit Değildir'],
                lt: ['Küçüktür'],
                lte: ['Küçük Eşittir'],
                gt: ['Büyüktür'],
                gte: ['Büyük eşittir'],
                between: ['Arasında'],
                dateIs: ['Tarih eşittir'],
                dateIsNot: ['Tarih eşit değildir'],
                dateBefore: ['Tarihten öncesi'],
                dateAfter: ['Tarihten sonrası'],
                matchAll: ['Hepsini Eşleştir'],
                matchAny: ['Herhangi Biriyle Eşleştir'],
                addRule: ['Kural Ekle'],
                clear: ['Temizle'],
                apply: ['Uygula'],
                emptyMessage: ['Sonuç Bulunamadı.'],
            });
            locale('tr');
        } else {
            addLocale('eng', {
                startsWith: ['Starts with'],
                contains: ['Contains'],
                notContains: ['Not contains'],
                endsWith: ['Ends with'],
                equals: ['Equals'],
                notEquals: ['Not equals'],
                lt: ['Less than'],
                lte: ['Less than or equal to'],
                gt: ['Greater than'],
                gte: ['Greater than or equal to'],
                between: ['Between'],
                dateIs: ['Date is'],
                dateIsNot: ['Date is not'],
                dateBefore: ['Date before'],
                dateAfter: ['Date after'],
                matchAll: ['Match all'],
                matchAny: ['Match any'],
                addRule: ['Add Rule'],
                clear: ['Clear'],
                apply: ['Apply'],
                emptyMessage: ['No results found'],
            });
            locale('eng');
        }
    }, [language])

    //S.Soytürk yoruma aldı. Tabloda "gotoselection" yaparken burası hata veriyor.
    // useEffect(() => {
    //     if (selectedFeature.id) {
    //         const selectedLayerName = selectedFeature.id.split('.')[0];
    //         projects.map((layerGroup: any) => {
    //             const selectedLayer = layerGroup.layers.find((layer: any) => layer.name === selectedLayerName);
    //             if (selectedLayer) {
    //                 dispatch(setSelectedLayerInfo(selectedLayer))
    //                 const url = services.find((service: any) => service.id === selectedLayer.service_id)?.url
    //                 FeatureRestApi.getFeatures(url, selectedLayer.name).then((res: any) => {
    //                     const copyFeatures = Object.assign([], res.data.features)
    //                     dispatch(setFeatures(copyFeatures));
    //                 })
    //             }
    //         })
    //     }

    // }, [selectedFeature, transactStatus])

    useEffect(() => {
        if(buttonState === ButtonState.INFO && selectedFeature && selectedFeature.length === 1 && selectedFeature[0].type){
            dispatch(setShowInfoWindow(true))
            dispatch(setShowInfoFeature(selectedFeature))
        }
         
    }, [selectedFeature])

    useEffect(() => {
        getServices();
    }, [])

    useEffect(() => {
        if (selectedLayer) {
            // getServices();
            setShowTable(selectedLayer)
        }
    }, [selectedLayer])
    const selectPrint = (val: any) => {
        setSelectedPrint(val)
    }
    const rightClickHandler = (event: any) => {
        event.preventDefault();
    }

    useEffect(() => {
        if (loginStatus === 'LOGOUT') {
            navigate("login");
            localStorage.removeItem('token')
        }
        return () => {
            dispatch(setButtonState(ButtonState.NONE))
        }
    }, [loginStatus])

    useEffect(() => {
        if (toastVisibility) {
            toast.current?.show({ severity: 'success', detail: ('Giriş başarılı'), life: 3000 });
        }
    }, [toastVisibility])

    useEffect(() => {
        if (importCompleted) {
            toast.current?.show({ severity: 'success', detail: ('Katman içe aktarıldı!'), life: 3000 });
        }
    }, [importCompleted])

    useEffect(() => {
        if (projects && projects.length > 0) {
            const beyogluLayerGroup: any = projects.find((layerGroup: any) => layerGroup.name === 'beyoglu');
            const yolLayer: any = beyogluLayerGroup?.layers.find((layer: any) => layer.name === 'beyoglu_yol_usr');
            const fields: any = yolLayer?.fields;

            dispatch(setAllFields(fields))

            //domainleri bulup layerlarına göre gruplandırdık.
            let domainArray: any[] = [];
            let allDomainFields: any[] = [];

            projects.map((project: any) => {
                if (project.layer_groups && project.layer_groups.length > 0) {
                    project.layer_groups.map((layerGroup: any) => {
                        domainArray = [];
                        layerGroup.layers.map((layer: any) => {
                            layer.fields.map((field: any) => {
                                if (field.type === 'Domain') {
                                    domainArray.push(field)
                                }
                            })
                        })
                        const domainObject = {
                            layerId: layerGroup.id,
                            domains: domainArray
                        }
                        allDomainFields.push(domainObject)
                    })
                }
            })
            dispatch(setDomainFields(allDomainFields))
        }
    }, [projects])

    // useEffect(() => {
    //     const layerArr: any[] = []
    //     if (projects && projects.length > 0) {
    //         const newArray = projects.map((project: Project) => {
    //             const layerGroups = project.layer_groups.map((layerGroup: LayerGroup) => {
    //                 const layers = layerGroup.layers.find((layer: any) => {
    //                     if (layer.visible) {
    //                         console.log(layer);
    //                         const layerObj = {
    //                             name: layer.name,
    //                             visible: layer.visible
    //                         }
    //                         layerArr.push(layerObj)
    //                     }
    //                 })
    //             })
    //         })
    //         localStorage.setItem('sidebarLayersAreOpened', JSON.stringify(layerArr))
    //     }
    // }, [projects])

    // const getLayers = () => {
    //     LayerRestApi.getLayerGroups().then((response) => {
    //         const success = response.data.success
    //         if (success) {
    //             const layerGroups = response.data.layerGroups.filter((layerGroup: any) =>
    //                 layerGroup.name !== 'default_group'

    //             );

    //             // const immovables = layerGroups.find((layer: any) => { return layer.name === 'beyoglu' })
    //             // if (immovables) {
    //             //     immovables.layers.find((obj: any) => {
    //             //         if (obj.name === 'beyoglu_yol_usr') {
    //             //             obj.visible = true;
    //             //         }
    //             //     })
    //             // }
    //             dispatch(setLegendVisibility(true));
    //             dispatch(setLayerGroups(layerGroups));
    //         }
    //     });
    // }

    const getProjects = async () => {
        try {
            const response = await ProjectRestApi.getProjects();
            dispatch(setProjects(response.data.projects));

        } catch (error) {
            toast.current?.show({ severity: 'error', detail: ('Bir hata oluştu'), life: 3000 });
        }
    }

    const getServices = () => {
        ServiceRestApi.getServices().then((response: any) => {
            const success = response.data.success
            if (success) {
                const services = response.data.services.services
                dispatch(setServices(services));
            }
        }).catch((err: any) => {
        })
    }
    const toastMessage = (message: any) => {
        toast.current?.show({ severity: 'warn', summary: (t('TEXT.Warning')), detail: (message), life: 5000 });
    }

    return (
        <>
            <DigiCallbackContextProvider>
                <div className='home'>
                    <div style={{ visibility: screenshotVisibility ? 'visible' : 'hidden' }}>
                        <ScreenShot />
                    </div>
                    <div className='navbar non-printable'>
                        <Navbar showMessage={toastMessage} />
                        <div className={sidebarVisibility ? 'show' : 'hide'}>
                            <Sidebar />
                        </div>
                        <div className={!sidebarVisibility && selectedLayer.id ? 'show' : 'hide'}>
                            <div className='selected-layer'>{selectedLayer.name}</div>
                        </div>
                    </div>
                    {legendVisibility && <div className='legend-container'>
                        <Legend />
                    </div>}
                    {showInfoWindow && <Information />}
                    <div style={sidebarButton ? { width: `calc(100% - ${sidebarWidth}px)`, height: '100%', marginLeft: 'auto' } : { width: '100%', height: '100%' }}>
                    <Splitter //Bir tek table açıldığında splitter gözükmeli
                        position="horizontal"
                        primaryPaneMinWidth='80%'
                        primaryPaneMaxWidth={0}
                        primaryPaneWidth="100%"
                        primaryPaneHeight="65%"
                        primaryPaneMinHeight={0}
                        primaryPaneMaxHeight="80%"
                        maximizedPrimaryPane={!tableVisibility}
                        postPoned={false}
                    >
                        {/* <div className={`containers ${tableVisibility ? 'containers-table-opened' : ''}`}> */}
                        <Splitter
                            position="vertical"
                            primaryPaneMinWidth='0%' //'10%'
                            primaryPaneMaxWidth='100%'
                            primaryPaneWidth={selectedPrint === 'only-map' ? "100%" : selectedPrint === 'both' || !selectedPrint ? "50%" : "0%"}
                            dispatchResize={true}
                            postPoned={false}
                                className={`test2 ${isFullScreen ? 'test1' : ''}`}
                            primaryPaneClassName={`${isFullScreen && !isPanoMiniScreen ? 'is-map-mini-screen' : ''}`}
                            secondaryPaneClassName={`${isFullScreen && isPanoMiniScreen ? 'is-pano-mini-screen' : 'x'}`}
                        >
                                <MapContainer showMessage={toastMessage} tableVisibility={tableVisibility} />

                            <PanoramaContainer showMessage={toastMessage} />
                        </Splitter>
                        {/* </div> */}
                        {/* <div className={`table ${tableVisibility ? 'table-opened' : ''}`} > */}
                        <>

                            {tableVisibility && showTable && <Table />}
                            {!tableVisibility && !showTable && <></>}
                        </>
                    </Splitter>
                    </div>
                  
                    <Footer />

                    <Help />
                    <Intro />
                    <Import />
                    {displayPrint && <Print selectedPrint={selectPrint} />}
                    {displayExport && <Export />}
                    <Toast ref={toast} position='bottom-center' style={{ zIndex: '9 !important' }} />
                </div>
            </DigiCallbackContextProvider>
        </>
    )
}