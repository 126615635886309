import Draw, { DrawEvent } from 'ol/interaction/Draw';
import { useContext, useEffect, useState } from 'react'
import { MapContext } from '../MapContext'
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import Text from 'ol/style/Text';
import { never } from 'ol/events/condition';
import { setDrawState } from '../../../../../state/features/drawStateSlice';
import { DrawStateType } from '../../../../../util/model/drawState';
import { useDispatch } from 'react-redux';
import { WKT } from 'ol/format';
import { setMapCoordinates } from '../../../../../state/features/coordinatesSlice';
import { getLength } from 'ol/sphere';
import { setButtonState } from '../../../../../state/features/buttonStateSlice';
import { ButtonState } from '../../../../shared/button-state/ButtonState';
import { MultiLineString } from 'ol/geom';
import { GeomDataHolder } from '../../../panorama-container/easy-pano-imports';

interface Props {
    source: any
}

export const DrawLinestring = (props: Props) => {
    const map = useContext(MapContext);
    const dispatch = useDispatch();
    const [labeltext, setLabel] = useState<any>()



    useEffect(() => {
        let draw = new Draw({
            source: props.source,
            stopClick: true,
            type: 'LineString',
            style: new Style({
                stroke: new Stroke({
                    color: '#3ea1f1',
                    width: 3,
                }),
                fill: new Fill({
                    color: '#3ea1f1',
                }),
                image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({
                        color: '#3ea1f1',
                    }),
                }),
            }),
            finishCondition: never,
            condition: function (e: any) {
                //sadece solla çizim yapıyor ve sağla bitiriyor
                if (e.originalEvent.buttons === 1) {
                    return true;
                } else if (e.originalEvent.buttons === 2) {
                    if (!labeltext) {
                        if(!((window as any).buttonState === ButtonState.ADD && (window as any).drawStartOfDigi)
                        && (window as any).buttonState !== ButtonState.EDIT){
                            dispatch(setButtonState(ButtonState.NONE));
                        }
                        dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
                    }
                    draw.finishDrawing();
                    return false;
                }
                else {
                    return false;
                }
            },

        });

        map.addInteraction(draw);

        draw.on('drawend', (event: any) => {
            const _geom = event.feature?.getGeometry().clone();
            const label = getLength(_geom)//.toFixed(2) + 'm';

            setLabel(label)
            if (label) {
                let geom;
                const wkt = new WKT();
                const _geomClone: any = event.feature.getGeometry().clone()
                geom = new MultiLineString([_geomClone.transform('EPSG:3857', 'EPSG:4326').getCoordinates()]);
                const geomWktLine = wkt.writeGeometry(geom);
                geom.transform('EPSG:4326', 'EPSG:3857');
                const gdh: any = GeomDataHolder.fromWKT(geomWktLine);
                (window as any).panogl.getScalable().getMainSketchLayer().addGlobal(gdh);
                (window as any).panogl.getScalable().getMainSketchLayer().refresh();
                setTextAfterDrawEnd(event, gdh.attributes._length);
                (window as any).panogl.getScalable().getMainSketchLayer().removeFromGlobal(gdh);
                (window as any).panogl.getScalable().getMainSketchLayer().refresh();
                // setTextAfterDrawEnd(event, label)
            }
            dispatch(setDrawState({ drawStateType: DrawStateType.NONE }))
        });

        return () => {
            map.removeInteraction(draw)
        }

    }, [map])

    /**
     * Çizim bittikten sonra ölçüm değerini çizilen nesnenin üzerine yazar. 
     */
    const setTextAfterDrawEnd = (event: DrawEvent, length: any) => {
        const feature = event.feature;
        length = length//.toFixed(2);
        feature.setProperties({'alias': 'draws'})
        // console.log("drawss");
        
        feature.setStyle(new Style({
            stroke: new Stroke({
                color: '#3ea1f1',
                width: 3,
            }),
            fill: new Fill({
                color: '#3ea1f1',
            }),
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: '#3ea1f1',
                }),
            }),
            text: new Text({
                text: `${length}`,
                font: "bold 15px 'Open Sans', 'Arial Unicode MS', 'sans-serif'",
                placement: 'point',
                offsetY: -15,
                fill: new Fill({
                    color: 'white',
                }),
                stroke: new Stroke({
                    color: 'black',
                    width: 3,
                }),
            }),
        }));

        //aynı çizimi panorama üzerinde de göstermek için
        const wkt = new WKT();
        const wktFeature = wkt.writeFeature(feature)
        const result = {
            type: 'Point',
            feature: wktFeature,
            text: `${length}`
        }

        dispatch(setMapCoordinates(result))
    }

    return (
        <>
        </>
    )
}
